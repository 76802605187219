export const items = [
    {
        id: 1,
        src: 'https://media.istockphoto.com/id/1416048929/photo/woman-working-on-laptop-online-checking-emails-and-planning-on-the-internet-while-sitting-in.jpg?s=612x612&w=0&k=20&c=mt-Bsap56B_7Lgx1fcLqFVXTeDbIOILVjTdOqrDS54s=',
        title: ''
    },
    {
        id: 2,
        src: 'https://media.istockphoto.com/id/479566382/photo/young-beautiful-girl-working-on-a-computer.jpg?s=612x612&w=0&k=20&c=S2GtDdCyDTLCyLm4u9Cu4zCZwqFQtOPlSAhK-zjTlHQ=',
        title: 'title-1'
    },
    {
        id: 3,
        src: 'https://media.istockphoto.com/id/1075846806/photo/young-college-student-studying-at-library.jpg?s=612x612&w=0&k=20&c=WiTXYqzG9p1j37oYZ27JTInVqOW5j33WbEtp2Cans9E=',
        title: 'title-2'
    },
    {
        id: 4,
        src: 'https://media.istockphoto.com/id/1319766088/photo/mid-adult-couple-working-on-home-finance.jpg?s=612x612&w=0&k=20&c=9jF-C7RvPAS0rTy7oYg5nd5RTw4KNIRGGa57irQfDX4=',
        title: 'title-3'
    },
    {
        id: 5,
        src: 'https://media.istockphoto.com/id/1216824129/photo/distance-learning-online-education.jpg?s=612x612&w=0&k=20&c=cc44IYdzx82AgtQJ2ODRhw2SlgpxBT7Qz_yFWt4Jqds=',
        title: 'title-4'
    },
]